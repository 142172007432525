// gatsby-browser.js
import "@fontsource/bebas-neue"
import "@fontsource/barlow"
import "@fontsource/barlow-condensed"
import "@fontsource/barlow-condensed/500.css"
import "@fontsource/barlow-semi-condensed/600.css"

import "./src/styles/layout.scss"
import { priceSpiderRebind } from "./src/services/util"

export const onRouteUpdate = () => {
    setTimeout(() => {
        document.body.scrollTop = 0
        priceSpiderRebind()
    }, 100)
}

// NOTE: This is necessary to avoid page scrollTo [0,0] after setting a param using use-query-params
// NOTE: Include here all the params that should avoid the scroll to top
// INFO: https://github.com/alexluong/gatsby-packages/issues/33
const avoidScrollToTop = [`functionality=`, `location=`, `category=`]
export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    // hack to override `shouldUpdateScroll` behavior to bypass useQueryParams
    const shouldAvoidScrollToTop = avoidScrollToTop.reduce((pV, cV) => {
        return pV || location.search.includes(cV)
    }, false)
    if (shouldAvoidScrollToTop) {
        return false
    }

    // IF LOCATION hash HAS scl- then return false
    // scl goes for: section content link, for Table Contents coming from DatoCMS that cannot have refs 
    const hash = location.hash;
    const pieces = hash.split('-');
    if(pieces[0] === '#scl'){
        return false;
    }
    if(hash === '#ccpa-request'){return false}
    
    const currentPosition = getSavedScrollPosition(location)
    return currentPosition || [0, 0]
}
